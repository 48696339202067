/* Replace "custom-select-input" with your actual class name if it differs */
.custom-select-input .MuiAutocomplete-option:hover {
  background-color: blue !important;
  color: white !important;
}


.virtualized-loading {
  width: 100px;
  height: 100px;
  border: 1px solid black;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  position: relative;
  /* Add this to position the ::after element relative to the .virtualized-loading container */
}

@keyframes dot-keyframes {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}

.loading-dots {
  text-align: center;
  width: 100%;
}

.loading-dots--dot {
  animation: dot-keyframes 2s infinite ease-in-out;
  background-color: #000;
  border-radius: 10px;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 8px;

  &:nth-child(1) {
    background-color: #0898E6;
  }

  &:nth-child(2) {
    background-color: #00C853;
    animation-delay: 0.5s;
  }

  &:nth-child(3) {
    background-color: #1976D2;
    animation-delay: 1s;
  }
}