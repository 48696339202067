.loading-spinner {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%) translateZ(0);
    top: 50%;
    width: 45px;
    height: 45px;
    z-index: 1;
    background: url('../../../../images/EB_Loading.png') center no-repeat;
    background-size: contain;
    /*    background: linear-gradient(45deg, #00b3ff, #00ff00); */
    animation: spinning 1.8s infinite ease-in-out;
}

.loading-spinner:after {
    content: "";
    position: absolute;
    width: 72px;
    height: 72px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 100%;
    margin: -15px;
    box-shadow: 0 2px 0 0 #0C95E5, 0 2px 0 0 #01C851;
    transition: all 1s linear;
    animation: lds-eclipse 1s linear infinite;
}



@keyframes spinning {
    0% {
        transform: translate(-50%, -50%) scale(1) translateZ(0);
    }

    50% {
        transform: translate(-50%, -50%) scale(1.1) translateZ(0);
    }

    100% {
        transform: translate(-50%, -50%) scale(1) translateZ(0);
    }
}

@keyframes lds-eclipse {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}