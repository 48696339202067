

@import url(https://fonts.googleapis.com/css?family=Open+Sans);

.search {
    position: relative;
    display: flex;
    cursor: pointer;
}

.searchTerm {
    width: 74%;
    border: 1px solid #205BD4;
    border-right: none;
    padding: 5px;
    height: 30.3px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: #0000;
    cursor: pointer;
}

.searchTerm:focus {
    color: #00B4CC;
}

.searchButton {
    width: 40px;
    height: 30px;
    border: 1px solid #205BD4;
    background: #205BD4;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 27px;
}