tr:nth-child(even) {
  background-color: #f6f6f6;
}

tr:hover td {
  background: #DBE9FA;
}

body {
  margin: 0;
  /* scrollbar-width: none; */
  /* overflow-x: hidden; */

  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Oxygen', 'Segoe UI',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  background-color: #aaa;
  /* or add it to the track */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.signaturePanel {
  display: block;
  border: 2px solid darkgrey;
  border-radius: 5px;
  width: 500px;
  height: 200px;
}

.signaturePanelMobile {
  display: block;
  border: 2px solid darkgrey;
  border-radius: 5px;
  width: 346px;
  height: 200px;
}

.Carousel-button-197 {
  background: transparent !important;
  margin-left: 7px;
}


/*the complete project is in the following link:
https://github.com/vkive/coupon-code.git
Follow me on Codepen
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.main,
.copy-button {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}


.copy-button {
  margin: 12px 0 17px 0;
  height: 42px;
  border-radius: 8px;
  padding: 0 5px;
  border: 1px solid #e1e1e1;
}

.copy-button input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
}

.copy-button button {
  cursor: pointer;
  padding: 9px 18px;
  background-color: transparent;
  font-weight: bold;
  color: #5468ff;
  border-radius: 12px;
  border: 1px solid #5468ff;
}

#myimage {
  background-image: url('./images/success.gif');
  background-position: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;

}

iframe#webpack-dev-server-client-overlay {
  display: none !important
}

/* .MuiDataGrid-sortIcon {
  opacity: inherit !important;
  color: #000 !important;
} */

/* 
.MuiDataGrid-root .MuiDataGrid-menuIcon {
  visibility: visible !important;
}
 */

.custom-header {
  background-color: #1976d2;
  color: white;
  font-weight: bold !important;
  text-align: center !important;
}