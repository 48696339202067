.custom-hover:hover td {
    background: #FFFFFF !important;
}

.order-item:hover {
    background-color: #cce7ff !important;
}

.order-toolbar-container {
    position: relative;
    transition: background-color 0.3s ease;
}

@media (min-width: 601px) {
    .order-toolbar-container:hover .order-toolbar {
        display: block !important;
        animation: fadeIn 0.3s ease-in-out;
        height: 26.9%;
    }

    .order-toolbar-container:hover .order-item {
        height: 134px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.order-toolbar {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 43%;
    display: none;
    padding: 5px;
    background-color: #fdfdfd !important;
    text-align: center;
    z-index: 99 !important;
    box-shadow: 0 2px 0 0 #0C95E5, 0 2px 0 0 #01C851;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}